import 'vanilla-cookieconsent'

// Cookie Consent
window.addEventListener('load', () => {
	var cc = initCookieConsent()

	cc.run({
		gui_options: {
			consent_modal: {
				layout: 'box',
				position: 'middle center',
				transition: 'zoom',
				swap_buttons: false
			},
			settings_modal: {
				layout: 'box',
				transition: 'zoom'
			}
		},

		current_lang: 'en',
		autoclear_cookies: true,
		page_scripts: true,
		auto_language: 'document',
		autorun: true,
		force_consent: true,
		hide_from_bots: true,
		cookie_name: 'jsm_cc_cookie',
		revision: 0,

		onFirstAction: function(user_preferences, cookie){
			// callback triggered only once on the first accept/reject action
		},

		onAccept: function (cookie) {
			// callback triggered on the first accept/reject action, and after each page load
		},

		onChange: function (cookie, changed_categories) {
			// callback triggered when user changes preferences after consent has already been given
		},

		languages: {
			'en': {
				consent_modal: {
					title: 'We use cookies!',
					description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
					primary_btn: {
						text: 'Accept all',
						role: 'accept_all'
					},
					secondary_btn: {
						text: 'Reject all',
						role: 'accept_necessary'
					}
				},
				settings_modal: {
					title: 'Cookie preferences',
					save_settings_btn: 'Save settings',
					accept_all_btn: 'Accept all',
					reject_all_btn: 'Reject all',
					close_btn_label: 'Close',
					cookie_table_headers: [
						{col1: 'Name'},
						{col2: 'Domain'},
						{col3: 'Expiration'},
						{col4: 'Description'}
					],
					blocks: [
						{
							title: 'Cookie usage',
							description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
						}, {
							title: 'Strictly necessary cookies',
							description: 'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true
							}
						}, {
							title: 'More information',
							description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
						}
					]
				}
			}
		}
	});
})